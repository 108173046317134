import { useRef, useEffect, useState, useContext } from 'react';
import { AppContext } from '../../../../contexts/AppContext';
import FamousPlaces from '../assets/famousPlacesBackground.svg';
import ArrowRight from '../assets/arrowRight.svg';
import Snowflakes from 'react-snowfall';
import '../animationParts.css';
import './landingPage.css';
import animationTimelineHandler from './timelines/timelineHandler';
import Survey from '../survey/page';
import SurveyVariant from '../surveyVariant/page';
import SwipeSurvey from '../swipeSurvey/page';
import anime from 'animejs';
import Footer from '../components/Footer';
import Header from '../components/Header';
import OpenQuestionPage from '../openQuestion/page';
import GiftPage from '../giftPage/giftPage';
import CampaignOverPage from '../campaignOver/page';
import { pages } from '../../../../App';

export default function XmasLandingPage() {
    const { setPage, clickTransaction, getMember } = useContext(AppContext);

    const [images, setImages] = useState([]);

    const famousePlacesRef = useRef(null);
    const estimatedTimeRef = useRef(null);
    const buttonRef = useRef(null);
    const arrowRightRef = useRef(null);

    const estimatedTimeLayer1Ref = useRef(null);
    const estimatedTimeLayer2Ref = useRef(null);
    const estimatedTimeLayer3Ref = useRef(null);
    const estimatedTimeLayer4Ref = useRef(null);
    const estimatedTimeTextRef = useRef(null);

    const buttonLayer1Ref = useRef(null);
    const buttonLayer2Ref = useRef(null);
    const buttonLayer3Ref = useRef(null);
    const buttonLayer4Ref = useRef(null);
    const buttonTextRef = useRef(null);

    useEffect(() => {
        getMember().then(json => console.log(json));

        clickTransaction('home');

        console.log(pages);
    }, []);

    useEffect(() => {
        const snowflake = document.createElement('img');
        snowflake.src = '/snowflake.svg';

        setImages([snowflake]);
    }, []);

    useEffect(() => {
        animationTimelineHandler({
            buttonLayer1Ref,
            buttonLayer2Ref,
            buttonLayer3Ref,
            buttonLayer4Ref,
            buttonTextRef,
            estimatedTimeLayer1Ref,
            estimatedTimeLayer2Ref,
            estimatedTimeLayer3Ref,
            estimatedTimeLayer4Ref,
            estimatedTimeTextRef,
            arrowRightRef,
        });

        anime({
            targets: arrowRightRef.current,
            direction: 'alternate',
            easing: 'easeInOutSine',
            position: 'absolute',
            left: ['130%', '150%'],
            transform: 'translate(-50%, -50%)',
            duration: 600,
            loop: true,
        });
    }, []);

    const handleGoToSurvey = event => {
        event.preventDefault();

        // setPage(<OpenQuestionPage load={'airline'} />);
        setPage(pages.Survey);
        // setPage(<SwipeSurvey />);
        // setPage(<SurveyVariant load={'retro'} />);
        // setPage(<GiftPage reward={50000} />);
        // setPage(<CampaignOverPage />);
    };

    return (
        <>
            <Snowflakes
                snowflakeCount={100}
                wind={[0, 0]}
                rotationSpeed={[1, 1]}
                radius={[1, 15]}
                images={images}
            />

            <Header />

            <div className="landing-page">
                <div className="landing-page__title-wrapper">
                    <img
                        onClick={handleGoToSurvey}
                        src={FamousPlaces}
                        alt="Famous places background vector"
                        className="famous-places"
                        ref={famousePlacesRef}
                    />

                    <h1>Ready to take a survey?</h1>
                </div>

                <div className="landing-page__buttons">
                    <div ref={estimatedTimeRef} className="button-container estimated">
                        <div ref={estimatedTimeLayer1Ref} className="layer"></div>
                        <div ref={estimatedTimeLayer2Ref} className="layer"></div>
                        <div ref={estimatedTimeLayer3Ref} className="layer"></div>
                        <div ref={estimatedTimeLayer4Ref} className="layer"></div>
                        <span ref={estimatedTimeTextRef} className="button-text">
                            Estimated Time: 1-2min
                        </span>
                    </div>

                    <button
                        onClick={handleGoToSurvey}
                        ref={buttonRef}
                        className="button-container"
                    >
                        <div ref={buttonLayer1Ref} className="layer"></div>
                        <div ref={buttonLayer2Ref} className="layer"></div>
                        <div ref={buttonLayer3Ref} className="layer"></div>
                        <div ref={buttonLayer4Ref} className="layer"></div>
                        <span ref={buttonTextRef} className="button-text">
                            Let's go!{' '}
                            <span className="arrow-right">
                                <img
                                    ref={arrowRightRef}
                                    src={ArrowRight}
                                    alt="Arrow right"
                                    className="arrow-right-img"
                                />
                            </span>
                        </span>
                    </button>
                </div>
            </div>

            <Footer />
        </>
    );
}
