import './InteractiveChoicePage.css';
import { useState, useContext, useEffect } from 'react';
import CustomButton from '../../ui/Button/Button';
import { AppContext } from '../../../contexts/AppContext';
import Ch3 from '../Ch3';
import PageScheme from '../../ui/pageScheme/pageScheme';
import ReactGA4 from 'react-ga4';
import Ch2 from '../Ch2';

export default function InteractiveChoicePage() {
    const { setPage, clickTransaction } = useContext(AppContext);
    const [answer, setAnswer] = useState(null);
    const [questionIndex, setQuestionIndex] = useState(0);

    const [questions] = useState([
        {
            title: 'With Icelandair Saga Club you can buy gift vouchers with your points',
            answers: {
                yes: 'Correct, members can buy gift cards from companies around the world, such as Hotels.com, , Global Hotel card, Amazon.com, Starbucks and many more. Members can also purchase Icelandair Gift Certificates with Saga Points.',
                no: 'Well yes you can, Members can buy gift cards from companies around the world, such as Hotels.com, , Global Hotel card, Amazon.com, Starbucks and many more.',
            },
        },
        {
            title: 'Can Saga Points be used to purchase flights?',
            answers: {
                yes: 'Yes, members can pay for their next Icelandair flight with money, Points or a combination of Points and money.',
                no: 'Well, members can pay for their next Icelandair flight with money, Points or a combination of Points and money.',
            },
        },
        {
            title: 'With Icelandair Saga Club you cannot use your points to purchase meals on board',
            answers: {
                yes: 'Saga Club members can use their Saga Points to pay for meals on board. They also earn Saga Points and Tier Credits from all meals purchases on board Saga Kitchen. It is important to present the Saga Club card at the time of purchase.',
                no: 'Saga Club members can use their Saga Points to pay for meals on board. They also earn Saga Points and Tier Credits from all meals purchases on board Saga Kitchen. It is important to present the Saga Club card at the time of purchase.',
            },
        },
    ]);

    useEffect(() => {
        ReactGA4.event({
            category: 'page',
            action: `CH2Q${questionIndex}`,
        });
    }, [questionIndex]);

    const handleNext = answer => {
        // TODO: fill transaction for answer

        if (questionIndex === questions.length - 1) {
            setPage(<Ch2 />);
        } else {
            setQuestionIndex(questionIndex + 1);
            setAnswer(null);
        }
    };

    return (
        <PageScheme>
            <div className="interactive-choice-page">
                <h1>{questions[questionIndex].title}</h1>

                {!answer && (
                    <div className="buttons">
                        <CustomButton text="yes" onClick={() => setAnswer('yes')} />
                        <CustomButton text="no" onClick={() => setAnswer('no')} />
                    </div>
                )}

                {answer && (
                    <div>
                        {answer === 'Yes' ? (
                            <div>
                                <p>{questions[questionIndex].answers.yes}</p>

                                <CustomButton
                                    text="Next"
                                    onClick={() => handleNext('yes')}
                                />
                            </div>
                        ) : (
                            <div>
                                <p>{questions[questionIndex].answers.no}</p>

                                <CustomButton
                                    text="Next"
                                    onClick={() => handleNext('no')}
                                />
                            </div>
                        )}
                    </div>
                )}
            </div>
        </PageScheme>
    );
}
