import anime from "animejs";

export const estimatedTimeAnimationTimeline = ({ timeline, refs }) => {
    timeline.add({
        targets: refs.layer1Ref.current,
        maxWidth: "100%",
        duration: 400,
        easing: "easeInOutSine",
    });

    timeline.add(
        {
            targets: refs.layer2Ref.current,
            maxWidth: "calc(100% - 20px)",
            duration: 600,
            easing: "easeInOutSine",
        },
        "-=400"
    );

    timeline.add(
        {
            targets: refs.layer3Ref.current,
            maxWidth: "calc(100% - 20px)",
            duration: 700,
            easing: "easeInOutSine",
        },
        "-=500"
    );

    timeline.add(
        {
            targets: refs.layer4Ref.current,
            maxWidth: "calc(100% - 20px)",
            duration: 900,
            easing: "easeInOutSine",
            complete: () => {
                anime({
                    targets: refs.textRef.current,
                    opacity: 1,
                    duration: 300,
                    easing: "easeInOutSine",
                });
            },
        },
        "-=600"
    );
};
