import { useRef, useEffect } from "react";
import "./Textarea.css";
import { layersAnimation } from "./timeline/layers";

export default function Textarea({ value, onChange, placeholder, refs }) {
    const layersRefs = {
        layer1: useRef(null),
        layer2: useRef(null),
        layer3: useRef(null),
        layer4: useRef(null),
    };

    useEffect(() => {
        layersAnimation(layersRefs);
    }, []);

    return (
        <div className="textarea-wrapper">
            <div className="layer" ref={refs.layer1}></div>
            <div className="layer" ref={refs.layer2}></div>
            <div className="layer" ref={refs.layer3}></div>
            <textarea
                value={value}
                onChange={onChange}
                className="layer"
                placeholder={placeholder}
                ref={refs.layer4}
            ></textarea>
        </div>
    );
}
