import anime from "animejs";
import { secretGiftAnimationTimeline } from "./secretGiftTimeline";
import { santaAnimationTimeline } from "./santaTimeline";
import { buttonAnimationTimeline } from "./buttonTimeline";
import { shoeAnimationTimeline } from "./shoeTimeline";

export default function animationTimelineHandler(refs) {
    const timeline = anime.timeline();

    santaAnimationTimeline({
        timeline: timeline,
        santasHeadRef: refs.santasHeadRef,
        santasHandsRef: refs.santasHandsRef,
    });

    secretGiftAnimationTimeline({
        timeline: timeline,
        secretGiftRef: refs.secretGiftRef,
    });

    buttonAnimationTimeline({
        timeline: timeline,
        refs: {
            layer1Ref: refs.layer1Ref,
            layer2Ref: refs.layer2Ref,
            layer3Ref: refs.layer3Ref,
            layer4Ref: refs.layer4Ref,
            textRef: refs.textRef,
        },
    });

    shoeAnimationTimeline({
        timeline,
        shoeRef: refs.shoeRef,
    });

    timeline.play();
}
