import anime from 'animejs';

export const buttonAnimationTimeline = ({ timeline, refs, delay = 0 }) => {
    timeline.add(
        {
            targets: refs.layer1Ref.current,
            maxWidth: '100%',
            duration: 400,
            easing: 'easeInOutSine',
        },
        `+=${delay}`,
    );

    timeline.add(
        {
            targets: refs.layer2Ref.current,
            maxWidth: '97.5%',
            duration: 600,
            easing: 'easeInOutSine',
        },
        '-=400',
    );

    timeline.add(
        {
            targets: refs.layer3Ref.current,
            maxWidth: '97.5%',
            duration: 700,
            easing: 'easeInOutSine',
        },
        '-=500',
    );

    timeline.add(
        {
            targets: refs.layer4Ref.current,
            maxWidth: '97.5%',
            duration: 900,
            easing: 'easeInOutSine',
            complete: () => {
                anime({
                    targets: refs.textRef.current,
                    opacity: 1,
                    duration: 300,
                    easing: 'easeInOutSine',
                });
            },
        },
        '-=600',
    );
};

export const nextButtonAnimationTimeline = refs => {
    const timeline = anime.timeline();

    timeline.add({
        targets: refs.layer1Ref.current,
        maxWidth: '100%',
        duration: 400,
        easing: 'easeInOutSine',
    });

    timeline.add(
        {
            targets: refs.layer2Ref.current,
            maxWidth: '97.5%',
            duration: 600,
            easing: 'easeInOutSine',
        },
        '-=400',
    );

    timeline.add(
        {
            targets: refs.layer3Ref.current,
            maxWidth: '97.5%',
            duration: 700,
            easing: 'easeInOutSine',
        },
        '-=500',
    );

    timeline.add(
        {
            targets: refs.layer4Ref.current,
            maxWidth: '97.5%',
            duration: 900,
            easing: 'easeInOutSine',
            complete: () => {
                anime({
                    targets: refs.textRef.current,
                    opacity: 1,
                    duration: 300,
                    easing: 'easeInOutSine',
                });
            },
        },
        '-=600',
    );

    timeline.play();
};

export const buttonDisappearAnimationTimeline = refs => {
    const timeline = anime.timeline();

    timeline.add({
        targets: refs.textRef.current,
        opacity: 0,
        duration: 300,
        easing: 'easeInOutSine',
    });

    timeline.add(
        {
            targets: refs.layer4Ref.current,
            maxWidth: '0%',
            opacity: '0',
            duration: 900,
            easing: 'easeInOutSine',
        },
        // "-=600"
    );

    timeline.add(
        {
            targets: refs.layer3Ref.current,
            maxWidth: '0%',
            opacity: '0',
            duration: 800,
            easing: 'easeInOutSine',
        },
        '-=600',
    );

    timeline.add(
        {
            targets: refs.layer2Ref.current,
            maxWidth: '0%',
            opacity: '0',
            duration: 700,
            easing: 'easeInOutSine',
        },
        '-=500',
    );

    timeline.add(
        {
            targets: refs.layer1Ref.current,
            maxWidth: '0%',
            opacity: '0',
            duration: 600,
            easing: 'easeInOutSine',
        },
        '-=400',
    );

    timeline.play();
};

export const reversedButtonAnimationTimeline = refs => {
    console.log(refs.textRef.current);

    const timeline = anime.timeline();

    timeline.add({
        targets: refs.layer1Ref.current,
        maxWidth: '100%',
        transform: 'translate(-50%, -50%) scaleX(-1)',
        duration: 400,
        easing: 'easeInOutSine',
        delay: 1000,
    });

    timeline.add(
        {
            targets: refs.layer2Ref.current,
            maxWidth: '97.5%',
            transform: 'translate(-50%, -50%) scaleX(-1)',
            duration: 600,
            easing: 'easeInOutSine',
        },
        '-=400',
    );

    timeline.add(
        {
            targets: refs.layer3Ref.current,
            maxWidth: '97.5%',
            transform: 'translate(-50%, -50%) scaleX(-1)',
            duration: 700,
            easing: 'easeInOutSine',
        },
        '-=500',
    );

    timeline.add(
        {
            targets: refs.layer4Ref.current,
            maxWidth: '97.5%',
            transform: 'translate(-50%, -50%) scaleX(-1)',
            duration: 900,
            easing: 'easeInOutSine',
            complete: () => {
                anime({
                    targets: refs.textRef.current,
                    opacity: 1,
                    duration: 300,
                    easing: 'easeInOutSine',
                });
            },
        },
        '-=600',
    );

    timeline.play();
};

export const thanksButtonAnimationTimeline = refs => {
    console.log(refs.textRef.current);

    const timeline = anime.timeline();

    timeline.add({
        targets: refs.layer1Ref.current,
        maxWidth: '100%',
        transform: 'translate(-50%, -50%) scaleX(-1)',
        duration: 400,
        easing: 'easeInOutSine',
        delay: 1000,
    });

    timeline.add(
        {
            targets: refs.layer2Ref.current,
            maxWidth: '97.5%',
            transform: 'translate(-50%, -50%) scaleX(-1)',
            duration: 600,
            easing: 'easeInOutSine',
        },
        '-=400',
    );

    timeline.add(
        {
            targets: refs.layer3Ref.current,
            maxWidth: '97.5%',
            transform: 'translate(-50%, -50%) scaleX(-1)',
            duration: 700,
            easing: 'easeInOutSine',
        },
        '-=500',
    );

    timeline.add(
        {
            targets: refs.layer4Ref.current,
            maxWidth: '97.5%',
            transform: 'translate(-50%, -50%) scaleX(-1)',
            duration: 900,
            easing: 'easeInOutSine',
        },
        '-=600',
    );

    timeline.add({
        targets: refs.textRef.current,
        opacity: 1,
        duration: 300,
        easing: 'easeInOutSine',
    });

    timeline.play();
};
