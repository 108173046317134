export const santaAnimationTimeline = ({ timeline, santasHeadRef, santasHandsRef }) => {
    timeline.add({
        targets: santasHeadRef.current,
        translateX: '-50%',
        bottom: '1%',
        duration: 450,
        delay: 2200,
        easing: 'easeInOutSine',
    });

    timeline.add({
        targets: santasHeadRef.current,
        translateX: '-50%',
        bottom: '0%',
        duration: 400,
        easing: 'easeInOutSine',
    });

    timeline.add({
        targets: santasHeadRef.current,
        rotate: '5deg',
        translateX: '-50%',
        duration: 400,
        easing: 'easeInOutSine',
    });

    timeline.add({
        targets: santasHeadRef.current,
        rotate: '-5deg',
        translateX: '-50%',
        duration: 400,
        easing: 'easeInOutSine',
    });

    timeline.add({
        targets: santasHeadRef.current,
        rotate: '5deg',
        translateX: '-50%',
        duration: 400,
        easing: 'easeInOutSine',
    });

    timeline.add({
        targets: santasHeadRef.current,
        rotate: '-5deg',
        translateX: '-50%',
        duration: 400,
        easing: 'easeInOutSine',
    });

    timeline.add({
        targets: santasHeadRef.current,
        rotate: '5deg',
        translateX: '-50%',
        duration: 400,
        easing: 'easeInOutSine',
    });

    timeline.add({
        targets: santasHeadRef.current,
        rotate: '0deg',
        translateX: '-50%',
        duration: 400,
        easing: 'easeInOutSine',
    });

    timeline.add({
        translateX: '-50%',
        targets: santasHeadRef.current,
        bottom: '-1000%',
        duration: 400,
        easing: 'easeInOutSine',
    });

    timeline.add({
        targets: santasHandsRef.current,
        bottom: '-1000%',
        duration: 400,
        easing: 'easeInOutSine',
    });
};
