export const swipeSurveyQuestions = () => {
    return [
        {
            question:
                'With Icelandair Saga Club you can buy gift cards with your Saga Points',
            answers: {
                yes: 'Correct, members can buy gift cards from companies around the world, such as Hotels.com, Global Hotel card, Amazon.com, Starbucks and many more. Members can also purchase Icelandair Gift Certificates with Saga Points.',
                no: 'Well yes you can, Members can buy gift cards from companies around the world, such as Hotels.com, Global Hotel card, Amazon.com, Starbucks and many more.',
            },
        },
        {
            question: 'Can Saga Points be used to purchase flights? ',
            answers: {
                yes: 'Yes, members can pay for their next Icelandair flight with money, Points or a combination of Points and money.',
                no: 'Well, members can pay for their next Icelandair flight with money, Points or a combination of Points and money.',
            },
        },
        {
            question:
                'With Icelandair Saga Club you cannot use your points to purchase meals on board',
            answers: {
                yes: 'Members can use their Saga Points to pay for refreshments onboard and they also earn Points and Tier Credits with their purchases. To use or earn Points onboard members must present their digital Saga Club card in the Icelandair App or Wallet or their physical card.',
                no: 'Members can use their Saga Points to pay for refreshments onboard and they also earn Points and Tier Credits with their purchases. To use or earn Points onboard members must present their digital Saga Club card in the Icelandair App or Wallet or their physical card.',
            },
        },
    ];
};
