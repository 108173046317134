import React, { useContext, useEffect, useState } from "react";
import { motion } from "framer-motion";
import { pages } from "../../App";
import FreeSingleAnswer from "../ui/Quiz/FreeSingleAnswer";
import PageScheme from "../ui/pageScheme/pageScheme";
import { AppContext } from "../../contexts/AppContext";
import CustomButton from "../ui/Button/Button";
import Infox from "./infox";
import ReactGA4 from "react-ga4";
import LargeButton from "../ui/Button/LargeButton";
import Ch1SemiCh3 from "./Ch1SemiCh3";

function Ch1SemiCh2(props) {
    const { setPage, clickTransaction } = useContext(AppContext);

    const [selected, setSelected] = useState(-1);

    const events = [
        "last_flight_time",
        "last_flight_reason",
        "last_flight_airline",
        "next_flight_time",
        "next_time_place",
        // "saga_club_ffp",
    ];
    function onload() {
        if (props.load) {
            const nI = events.indexOf(props.load) + 1;
            if (nI && nI < events.length) return nI;
        }
    }

    const [questionIndex, setQuestionIndex] = useState(0);

    useEffect(() => {
        ReactGA4.event({
            category: "page",
            action: "CH1Q" + questionIndex,
        });
    }, [questionIndex]);

    function handleAnswer(answer) {
        // const index = questionIndex;
        // clickTransaction(events[index], answer);

        setPage(<Ch1SemiCh3 />);
        // if (questionIndex + 1 >= questions.length) {
        //     setPage(<Infox answer={answer == 1 ? false : true} />);
        // } else setQuestionIndex(questionIndex + 1);
    }

    return (
        <PageScheme>
            <motion.div
                key={questionIndex}
                className={"flex f1"}
                initial={{ y: 10, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: -10, opacity: 0 }}
                transition={{ duration: 0.2 }}
            >
                <h1>
                    If yes, have you entered your Saga Club number prior your
                    flight?
                </h1>

                <LargeButton
                    text="Yes"
                    onClick={() => setSelected(1)}
                    style={
                        selected === 1
                            ? {
                                  backgroundColor: "var(--color-1)",
                                  color: "white",
                              }
                            : {}
                    }
                />
                <LargeButton
                    text="No"
                    onClick={() => setSelected(2)}
                    style={
                        selected === 2
                            ? {
                                  backgroundColor: "var(--color-1)",
                                  color: "white",
                              }
                            : {}
                    }
                />
                <LargeButton
                    text="No, but I retroclaimed my points"
                    onClick={() => setSelected(3)}
                    style={
                        selected === 3
                            ? {
                                  backgroundColor: "var(--color-1)",
                                  color: "white",
                              }
                            : {}
                    }
                />
                <LargeButton
                    text="Not applicable"
                    onClick={() => setSelected(4)}
                    style={
                        selected === 4
                            ? {
                                  backgroundColor: "var(--color-1)",
                                  color: "white",
                              }
                            : {}
                    }
                />

                <CustomButton
                    text={"Submit"}
                    onClick={() => handleAnswer(selected)}
                />
            </motion.div>
        </PageScheme>
    );
}

export default Ch1SemiCh2;
