import './Footer.css';
import WSFLogo from '../../assets/icelandairFooterLogo.svg';
import { useRef, useEffect, useState } from 'react';
import LittleSanta from '../../assets/littleSanta.svg';
import MessageCloud from '../../assets/messageCloud.svg';
import { littleSantaAnimationTimeline } from '../../survey/timelines/littleSantaTimeline';
import anime from 'animejs';

import SantaBubbleText1 from '../../assets/santaBubbleText1.svg';
import SantaBubbleText2 from '../../assets/santaBubbleText2.svg';
import SantaBubbleText3 from '../../assets/santaBubbleText3.svg';
import SantaBubbleText4 from '../../assets/santaBubbleText4.svg';

export default function Footer({
    withSanta = false,
    questionIndex,
    linkedSanta,
    santaDisappear = true,
}) {
    const littleSantaRef = useRef(null);
    const messageCloudRef = useRef(null);
    // const messageCloudTextRef = useRef(null);

    useEffect(() => {
        if (withSanta) {
            const littleSantaTimeline = anime.timeline();

            littleSantaAnimationTimeline(
                littleSantaTimeline,
                littleSantaRef,
                messageCloudRef,
                // messageCloudTextRef,
                santaDisappear,
            );

            littleSantaTimeline.play();
        }
    }, []);

    const [hints] = useState([
        'We have 13 Santas in Iceland that give presents in a shoe that the children place on the window',
        // 'We have 13 Santas in Iceland that give presents in bokk',
        'Our Christmas starts on the 24th of December at 6 pm with bells ringing in every church in the country',
        'The most popular Christmas present in Iceland is a book.',
        'If you would like to learn more about the Icelandic Christmas, click.',
    ]);

    const [hintsSvg] = useState([
        SantaBubbleText1,
        SantaBubbleText2,
        SantaBubbleText3,
        SantaBubbleText4,
    ]);

    return (
        <footer className={`footer ${withSanta ? 'with-santa' : ''} `}>
            {withSanta && (
                <>
                    <a
                        href="https://www.icelandair.com/blog/christmas-at-66-degrees-north/"
                        className="little-santa"
                        ref={littleSantaRef}
                    >
                        <img
                            src={LittleSanta}
                            alt="Little Santa"
                            className=""
                            // ref={littleSantaRef}
                        />
                    </a>

                    {linkedSanta ? (
                        <a
                            href="https://www.icelandair.com/blog/christmas-at-66-degrees-north/"
                            target="_blank"
                            ref={messageCloudRef}
                            className="message-cloud linked"
                        >
                            <img
                                src={hintsSvg[questionIndex - 1]}
                                alt="Message cloud"
                                // className="message-cloud"
                                // ref={messageCloudRef}
                            />
                        </a>
                    ) : (
                        <img
                            src={hintsSvg[questionIndex - 1]}
                            alt="Message cloud"
                            className="message-cloud"
                            ref={messageCloudRef}
                            style={{ opacity: 0 }}
                        />
                    )}

                    {/* <p className="message-cloud-text" ref={messageCloudTextRef}>
                        {hints[questionIndex - 1]}
                    </p> */}
                    {/* <div
                        className="message-cloud-text-wrapper"
                        ref={messageCloudTextRef}
                        style={{ opacity: 0 }}
                    >
                        {linkedSanta ? (
                            <p className="message-cloud-text linked">
                                If you would like to learn more about the Icelandic
                                Christmas, click{' '}
                                <a
                                    href="https://www.icelandair.com/blog/christmas-at-66-degrees-north/"
                                    target="_blank"
                                >
                                    here
                                </a>{' '}
                                .
                            </p>
                        ) : (
                            <p className="message-cloud-text">
                                {hints[questionIndex - 1]}
                            </p>
                        )}
                    </div> */}
                </>
            )}

            <div className="footer-buttons">
                <button>
                    <a href={'/faq'} target={'_blank'}>
                        FAQ
                    </a>
                </button>

                <button>
                    <a href={'/tnc'} target={'_blank'} className="desktop-tscs">
                        Terms & Conditions
                    </a>
                    <a href={'/tnc'} target={'_blank'} className="mobile-tscs">
                        Ts&Cs
                    </a>
                </button>

                <button>
                    <a
                        href={
                            'https://www.icelandair.com/support/terms-and-conditions/privacy-policy/'
                        }
                        target={'_blank'}
                    >
                        Privacy
                    </a>
                </button>
            </div>

            <a href={'https://wayfarersolutions.com/'} className="logo">
                <img src={WSFLogo} alt="Powered by WAYFARER" />
            </a>
        </footer>
    );
}
