import './surveyVariant.css';
import { useRef, useState, useContext, useEffect } from 'react';
import { AppContext } from '../../../../contexts/AppContext';
import { variantsSurveyQuestions } from './questions';
import ArrowRight from '../assets/arrowRight.svg';
import { submitButtonAnimationTimeline } from './timelines/buttonTimeline';
import variantsSurveyAnimationTimelineHandler from './timelines/timelineHandler';
import Snowflakes from 'react-snowfall';
import SwipeSurvey from '../swipeSurvey/page';
import anime from 'animejs';
import { buttonDisappearAnimationTimeline } from './timelines/buttonTimeline';
import { submitButtonDisappearAnimationTimeline } from '../survey/timelines/buttonTimeline';
import Header from '../components/Header';
import Footer from '../components/Footer';
import OpenQuestionPage from '../openQuestion/page';
import SubmitButton from '../components/SubmitButton';
import { variantColoring } from './timelines/variantColoring';

export default function SurveyVariant({ load }) {
    const events = ['partners', 'retro'];

    const eventIndex = events.indexOf(load);

    const [questionIndex, setQuestionIndex] = useState(
        eventIndex === +'-1' ? 0 : eventIndex,
    );
    const [selectedAnswer, setSelectedAnswer] = useState(-1);
    const [images, setImages] = useState([]);
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);

    const { setPage, clickTransaction, pages } = useContext(AppContext);

    const arrowRightRef = useRef(null);

    // const button1Ref = useRef(null);
    // const button2Ref = useRef(null);
    // const button3Ref = useRef(null);
    // const button4Ref = useRef(null);
    const submitButtonRef = useRef(null);

    const submitButtonTextRef = useRef(null);

    // const littleSantaRef = useRef(null);
    // const messageCloudRef = useRef(null);

    const submitButtonLayer1Ref = useRef(null);
    const submitButtonLayer2Ref = useRef(null);
    const submitButtonLayer3Ref = useRef(null);
    const submitButtonLayer4Ref = useRef(null);

    const buttonsRefs = [
        {
            buttonRef: useRef(null),
            textRef: useRef(null),
            layer1Ref: useRef(null),
            layer2Ref: useRef(null),
            layer3Ref: useRef(null),
            layer4Ref: useRef(null),
        },
        {
            buttonRef: useRef(null),
            textRef: useRef(null),
            layer1Ref: useRef(null),
            layer2Ref: useRef(null),
            layer3Ref: useRef(null),
            layer4Ref: useRef(null),
        },
        {
            buttonRef: useRef(null),
            textRef: useRef(null),
            layer1Ref: useRef(null),
            layer2Ref: useRef(null),
            layer3Ref: useRef(null),
            layer4Ref: useRef(null),
        },
        {
            buttonRef: useRef(null),
            textRef: useRef(null),
            layer1Ref: useRef(null),
            layer2Ref: useRef(null),
            layer3Ref: useRef(null),
            layer4Ref: useRef(null),
        },
    ];

    const questions = variantsSurveyQuestions();

    useEffect(() => {
        buttonsRefs.forEach(button => {
            button.layer1Ref.current.style.backgroundColor = '#ef19c1';
            button.layer4Ref.current.style.backgroundColor = '#051874';
        });

        buttonsRefs.forEach((_, index) => {
            variantsSurveyAnimationTimelineHandler(
                {
                    buttonLayer1Ref: buttonsRefs[index].layer1Ref,
                    buttonLayer2Ref: buttonsRefs[index].layer2Ref,
                    buttonLayer3Ref: buttonsRefs[index].layer3Ref,
                    buttonLayer4Ref: buttonsRefs[index].layer4Ref,
                    buttonTextRef: buttonsRefs[index].textRef,
                },
                index * 1.15 * 100,
            );
        });

        submitButtonAnimationTimeline({
            layer1Ref: submitButtonLayer1Ref,
            layer2Ref: submitButtonLayer2Ref,
            layer3Ref: submitButtonLayer3Ref,
            layer4Ref: submitButtonLayer4Ref,
            textRef: submitButtonTextRef,
        });

        setSubmitButtonDisabled(false);
    }, [questionIndex]);

    useEffect(() => {
        if (selectedAnswer === -1) {
            anime.remove(arrowRightRef.current);
            arrowRightRef.current.style.left = '75%';
        }
    }, [selectedAnswer, questionIndex, arrowRightRef]);

    useEffect(() => {
        if (selectedAnswer !== -1) {
            setSubmitButtonDisabled(false);

            anime({
                targets: arrowRightRef.current,
                direction: 'alternate',
                easing: 'easeInOutSine',
                position: 'absolute',
                left: ['75%', '85%'],
                transform: 'translate(-50%, -50%)',
                duration: 600,
                loop: true,
            });
        }
    }, [selectedAnswer]);

    useEffect(() => {
        setSelectedAnswer(-1);

        console.log('Change color');
    }, []);

    useEffect(() => {
        const snowflake = document.createElement('img');
        snowflake.src = '/snowflake.svg';

        setImages([snowflake]);
    }, []);

    const handleAnswerSubmit = event => {
        event.preventDefault();

        clickTransaction(
            events[questionIndex],
            questions[questionIndex].answers[selectedAnswer - 1],
        );

        if (selectedAnswer !== -1) {
            if (questionIndex === questions.length - 1) {
                const rightAnswerIndex = questions[questionIndex].answers.indexOf(
                    questions[questionIndex].rightAnswer,
                );

                variantColoring(rightAnswerIndex, buttonsRefs);

                setTimeout(() => {
                    setSubmitButtonDisabled(true);

                    buttonsRefs.forEach((_, index) => {
                        buttonDisappearAnimationTimeline(
                            buttonsRefs[index],
                            index * 1.15 * 100,
                        );
                    });

                    submitButtonDisappearAnimationTimeline({
                        textRef: submitButtonTextRef,
                        layer1Ref: submitButtonLayer1Ref,
                        layer2Ref: submitButtonLayer2Ref,
                        layer3Ref: submitButtonLayer3Ref,
                        layer4Ref: submitButtonLayer4Ref,
                    });

                    setTimeout(() => {
                        setPage(<OpenQuestionPage />);
                    }, 2000);
                }, 3000);
            } else {
                const rightAnswerIndex = questions[questionIndex].answers.indexOf(
                    questions[questionIndex].rightAnswer,
                );

                variantColoring(rightAnswerIndex, buttonsRefs);
                // variantColoring(buttonsRefs.map(button => button.layer4Ref));

                setTimeout(() => {
                    setSubmitButtonDisabled(true);

                    buttonsRefs.forEach((_, index) => {
                        buttonDisappearAnimationTimeline(
                            buttonsRefs[index],
                            index * 1.15 * 100,
                        );
                    });

                    submitButtonDisappearAnimationTimeline({
                        textRef: submitButtonTextRef,
                        layer1Ref: submitButtonLayer1Ref,
                        layer2Ref: submitButtonLayer2Ref,
                        layer3Ref: submitButtonLayer3Ref,
                        layer4Ref: submitButtonLayer4Ref,
                    });

                    setTimeout(() => {
                        setQuestionIndex(questionIndex + 1);
                        setSelectedAnswer(-1);
                    }, 2000);
                }, 3000);
            }
        }
    };

    return (
        <>
            <Snowflakes
                snowflakeCount={100}
                wind={[0, 0]}
                rotationSpeed={[1, 1]}
                radius={[1, 15]}
                images={images}
            />

            <Header />

            <div className="survey-variant-page">
                <h1 className="survey-variant-page__title">
                    {questions[questionIndex].question.text}{' '}
                    <span>{questions[questionIndex].question.span}</span>
                </h1>

                <div className="survey-buttons">
                    {questions[questionIndex].answers.map((answer, index) => (
                        <button
                            onClick={() => setSelectedAnswer(index + 1)}
                            ref={buttonsRefs[index].buttonRef}
                            className={`button-container ${
                                selectedAnswer === index + 1 ? 'selected' : ''
                            }`}
                            key={index}
                        >
                            <div
                                ref={buttonsRefs[index].layer1Ref}
                                className="layer"
                            ></div>
                            <div
                                ref={buttonsRefs[index].layer2Ref}
                                className="layer"
                            ></div>
                            <div
                                ref={buttonsRefs[index].layer3Ref}
                                className="layer"
                            ></div>
                            <div
                                ref={buttonsRefs[index].layer4Ref}
                                className="layer"
                                style={
                                    selectedAnswer === index + 1
                                        ? { backgroundColor: '#005acb' }
                                        : { backgroundColor: '#051874' }
                                }
                            ></div>
                            <span
                                ref={buttonsRefs[index].textRef}
                                className="button-text"
                            >
                                {questions[questionIndex].answers[index]}
                            </span>
                        </button>
                    ))}
                </div>

                {/* <button
                    onClick={handleAnswerSubmit}
                    ref={submitButtonRef}
                    className={`button-container submit-button ${
                        selectedAnswer !== -1 ? 'selected-option' : ''
                    }`}
                    disabled={submitButtonDisabled}
                >
                    <div ref={submitButtonLayer1Ref} className="layer"></div>
                    <div ref={submitButtonLayer2Ref} className="layer"></div>
                    <div ref={submitButtonLayer3Ref} className="layer"></div>
                    <div ref={submitButtonLayer4Ref} className="layer"></div>
                    <div
                        ref={submitButtonTextRef}
                        className="button-text"
                        style={{ opacity: 0 }}
                    >
                        <div className="button-text-content">
                            Submit{' '}
                            <span
                                className="arrow-right"
                                ref={arrowRightRef}
                                style={
                                    {
                                        // top: '50%',
                                        // left: '70%',
                                        // transform: 'translate(-50%, -50%)',
                                    }
                                }
                            >
                                <img
                                    ref={arrowRightRef}
                                    src={ArrowRight}
                                    alt="Arrow right"
                                    className="arrow-right-img"
                                />
                            </span>
                        </div>
                    </div>
                </button> */}

                <SubmitButton
                    label={'Submit'}
                    refs={{
                        submitButtonRef,
                        submitButtonLayer1Ref,
                        submitButtonLayer2Ref,
                        submitButtonLayer3Ref,
                        submitButtonLayer4Ref,
                        submitButtonTextRef,
                        arrowRightRef,
                    }}
                    onClick={handleAnswerSubmit}
                    selectedAnswer={selectedAnswer}
                    submitButtonDisabled={submitButtonDisabled}
                />
            </div>

            <Footer withSanta questionIndex={2} />
        </>
    );
}
