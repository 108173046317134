import anime from 'animejs';

export const littleSantaAnimationTimeline = (santaRef, cloudRef) => {
    const santaTimeline = anime.timeline();

    santaTimeline.add({
        targets: santaRef.current,
        bottom: '-10%',
        duration: 400,
        easing: 'easeInOutSine',
    });

    santaTimeline.add({
        targets: santaRef.current,
        bottom: '-9%',
        duration: 300,
        easing: 'easeInOutSine',
    });

    santaTimeline.add({
        targets: cloudRef.current,
        opacity: 1,
        duration: 200,
        easing: 'easeInOutSine',
    });

    santaTimeline.add({
        targets: cloudRef.current,
        opacity: 0,
        duration: 300,
        easing: 'easeInOutSine',
        delay: 5000,
    });

    santaTimeline.add({
        targets: santaRef.current,
        bottom: '-9%',
        duration: 300,
        easing: 'easeInOutSine',
    });

    santaTimeline.add({
        targets: santaRef.current,
        bottom: '-1000%',
        duration: 300,
        easing: 'easeInOutSine',
    });

    santaTimeline.play();
};
