import './App.css';
// import Footer from './components/Header-Footer/Footer';
import { useContext, useEffect } from 'react';
import { AppContext } from './contexts/AppContext';
// import Header from './components/Header-Footer/Header';
import DefaultError, { UnsignedError } from './components/pages/DefaultError';
import LandingPage from './components/pages/LandingPage';
import Missions from './components/pages/other-unused/Missions';
import QuestionMA from './components/pages/other-unused/Question-multiipleAnswer';
import QuestionSA from './components/pages/other-unused/Question-singleAnswer';
import SpinWheel from './components/pages/spinWheel/SpinWheel';
import Scratch3Cards from './components/pages/other-unused/Scratch3Cards';
import FlipCard from './components/pages/other-unused/FlipCard';
import { motion, AnimatePresence } from 'framer-motion';
import PreCh2 from './components/pages/PreCh2';
import PreCh3 from './components/pages/PreCh3';
import TnC, { closeIcon } from './components/TnC';
import FAQ from './components/FAQ';
import Ch1 from './components/pages/Ch1';
import ReactGA4 from 'react-ga4';
import Ch1SemiCh1 from './components/pages/Ch1SemiCh1';
import Ch1SemiCh2 from './components/pages/Ch1SemiCh2';
import Ch1SemiCh3 from './components/pages/Ch1SemiCh3';
import Ch1SemiCh4 from './components/pages/Ch1SemiCh4';
import OpenQuestionPage from './components/pages/animated/openQuestion/page';
import EarnPage from './components/pages/earnPage/earnPage';
import InteractiveChoicePage from './components/pages/interactiveChoicePage/page';
import GiftPage from './components/pages/animated/giftPage/giftPage';
import XmasLandingPage from './components/pages/animated/landingPage/landingPage';
import Header from './components/pages/animated/components/Header';
import Footer from './components/pages/animated/components/Footer';
import UserUnsignedPage from './components/pages/animated/userUnsigned/page';
import Survey from './components/pages/animated/survey/page';
import SurveyVariant from './components/pages/animated/surveyVariant/page';
import SwipeSurvey from './components/pages/animated/swipeSurvey/page';

const TRACKING_ID = 'G-7SNG9REQC2';
ReactGA4.initialize(TRACKING_ID);

export const pages = {
    Error: <DefaultError />,
    UnsignedError: <UserUnsignedPage />,
    // LandingPage: <LandingPage />,
    QuestionSA: <QuestionSA />,
    QuestionMA: <QuestionMA />,
    // Ch2: <PreCh2 />,
    SpinWheel: <SpinWheel />,
    Scratch3Cards: <Scratch3Cards />,
    FlipCard: <FlipCard />,
    // Ch1: <Ch1 />,
    Summary: <SpinWheel />,
    // Ch3: <PreCh3 />,
    Ch1SemiCh1: <Ch1SemiCh1 />,
    Ch1SemiCh2: <Ch1SemiCh2 />,
    Ch1SemiCh3: <Ch1SemiCh3 />,
    Ch1SemiCh4: <Ch1SemiCh4 />,
    // OpenQuestionPage: <OpenQuestionPage />,
    EarnPage: <EarnPage />,
    InteractiveChoicePage: <InteractiveChoicePage />,
    GiftPage: <GiftPage />,
    XmasLandingPage: <XmasLandingPage />,
    Survey: <Survey />,
    SurveyVariant: <SurveyVariant />,
    OpenQuestionPage: <OpenQuestionPage />,
    SwipeSurvey: <SwipeSurvey />,
};

function App() {
    const { page, terms, faq } = useContext(AppContext);

    function getPage() {
        return page || <DefaultError />;
    }

    if (window.location.pathname === '/tnc')
        return (
            <div className="App scrolled">
                <Header />

                <main className={'main'}>
                    <TnC
                        noClose
                        style={{
                            color: 'white',
                            padding: '20px',
                            maxWidth: '1000px',
                            margin: 'auto',
                        }}
                    />
                </main>
                <Footer />
            </div>
        );

    if (window.location.pathname === '/faq')
        return (
            <div className="App scrolled">
                <Header />

                <main className={'main'}>
                    <FAQ
                        noClose
                        style={{
                            padding: '20px',
                            maxWidth: '1000px',
                            margin: 'auto',
                        }}
                    />
                </main>
                <Footer />
            </div>
        );

    return (
        <div className="App">
            <main className={'main'}>
                <AnimatePresence mode={'wait'}>
                    <motion.div
                        style={{
                            // maxWidth: "800px",
                            display: terms || faq ? 'none' : '',
                        }}
                        key={page.type.name}
                        className={'flex f1'}
                        initial={{ y: 10, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        exit={{ y: -10, opacity: 0 }}
                        transition={{ duration: 0.2 }}
                    >
                        {getPage()}
                    </motion.div>
                </AnimatePresence>
                {terms || faq ? (
                    <>
                        <div
                            style={{
                                position: 'absolute',
                                top: 0,
                                backgroundColor: 'white',
                                overflowY: 'auto',
                                width: '100%',
                                zIndex: 100,
                            }}
                        >
                            {terms && (
                                <TnC
                                    style={{
                                        height: '85vh',
                                        padding: '20px',
                                        maxWidth: '1000px',
                                        margin: 'auto',
                                        overflow: 'scroll',
                                    }}
                                />
                            )}
                            {faq && (
                                <FAQ
                                    style={{
                                        height: '85vh',
                                        padding: '20px',
                                        maxWidth: '1000px',
                                        margin: 'auto',
                                        overflow: 'scroll',
                                    }}
                                />
                            )}
                        </div>
                        x
                    </>
                ) : null}
            </main>
        </div>
    );
}

export default App;
