import anime from "animejs";

export const layersAnimation = (refs) => {
    const timeline = anime.timeline();

    timeline.add({
        targets: refs.layer1.current,
        maxWidth: "100%",
        opacity: "1",
        duration: 400,
        easing: "easeInOutSine",
    });

    timeline.add(
        {
            targets: refs.layer2.current,
            maxWidth: "97.5%",
            opacity: "1",
            duration: 600,
            easing: "easeInOutSine",
        },
        "-=400"
    );

    timeline.add(
        {
            targets: refs.layer3.current,
            maxWidth: "97.5%",
            opacity: "1",
            duration: 700,
            easing: "easeInOutSine",
        },
        "-=500"
    );

    timeline.add(
        {
            targets: refs.layer4.current,
            maxWidth: "97.5%",
            opacity: "1",
            duration: 900,
            easing: "easeInOutSine",
        },
        "-=700"
    );

    timeline.play();
};

export const layersDisappearAnimationTimeline = (refs) => {
    const timeline = anime.timeline();

    timeline.add(
        {
            targets: refs.layer4.current,
            maxWidth: "0%",
            opacity: "0",
            duration: 700,
            easing: "easeInOutSine",
        }
        // "-=500"
    );

    timeline.add(
        {
            targets: refs.layer3.current,
            maxWidth: "0%",
            opacity: "0",
            duration: 600,
            easing: "easeInOutSine",
        },
        "-=400"
    );

    timeline.add(
        {
            targets: refs.layer2.current,
            maxWidth: "0%",
            opacity: "0",
            duration: 500,
            easing: "easeInOutSine",
        },
        "-=300"
    );

    timeline.add(
        {
            targets: refs.layer1.current,
            maxWidth: "0%",
            opacity: "0",
            duration: 400,
            easing: "easeInOutSine",
        },
        "-=200"
    );

    timeline.play();
};
