import { useState, useEffect, useContext, useReducer } from 'react';
import { useDragLayer } from 'react-dnd';
import Snowflakes from 'react-snowfall';
import './swipeSurvey.css';
import SwipeChallenge2 from '../../../ui/Quiz/SwipeChallenge2';
import { AppContext } from '../../../../contexts/AppContext';
import XMarkIcon from '../assets/xmarkIcon.svg';
import CheckMarkIcon from '../assets/checkMarkIcon.svg';

import TinderCard from 'react-tinder-card';
import { swipeSurveyQuestions } from './questions';
import AnswerModal from './answerModal';
import anime from 'animejs';
import Footer from '../components/Footer';
import Header from '../components/Header';

const initialState = {
    activeCard: null,
    swipeDirection: null,
    exitingCard: null,
};

function reducer(state, action) {
    switch (action.type) {
        case 'SET_ACTIVE_CARD':
            return {
                ...state,
                activeCard: action.payload,
            };

        case 'SET_SWIPE_DIRECTION':
            return {
                ...state,
                swipeDirection: action.payload,
            };

        case 'RESET':
            return initialState;

        default:
            return state;
    }
}

function SwipeSurvey({ load }) {
    const events = ['vouchers', 'flights', 'meal'];

    const eventIndex = events.indexOf(load);

    const [images, setImages] = useState([]);
    const [questions] = useState(swipeSurveyQuestions());
    const [isWindowOpened, setWindowOpened] = useState(false);
    const [answer, setAnswer] = useState(null);
    const [questionIndex, setQuestionIndex] = useState(
        eventIndex === +'-1' ? 0 : eventIndex,
    );

    const { setPage, clickTransaction } = useContext(AppContext);

    const [swipedDirection, setSwipedDirection] = useState(null);
    const [cardExited, setCardExited] = useState(null);

    const outOfFrame = name => {
        setCardExited(name);
    };

    const [state, dispatch] = useReducer(reducer, initialState);

    const swiped = (direction, card) => {
        if (!state.swipeDirection) {
            dispatch({ type: 'SET_SWIPE_DIRECTION', payload: direction });
            dispatch({ type: 'SET_ACTIVE_CARD', payload: card });
        }
    };

    const handleCardExit = card => {
        dispatch({ type: 'SET_EXITING_CARD', payload: card });
    };

    const handleNo = cardIndex => {
        setQuestionIndex(prev => prev + 1);
        console.log('no' + questions[questionIndex]);
        setAnswer(questions[questionIndex].answers.no);

        clickTransaction(events[questionIndex], 'no');

        anime({
            targets: `.tinder-card-${cardIndex}`,
            translateX: -2800,
            rotate: -60,
            duration: 600,
            easing: 'easeInOutSine',
        });

        setWindowOpened(true);
    };

    const handleYes = cardIndex => {
        setQuestionIndex(prev => prev + 1);
        console.log('yes' + questions[questionIndex]);
        setAnswer(questions[questionIndex].answers.yes);

        clickTransaction(events[questionIndex], 'yes');

        anime({
            targets: `.tinder-card-${cardIndex}`,
            translateX: 2800,
            rotate: 60,
            duration: 600,
            easing: 'easeInOutSine',
        });

        setWindowOpened(true);
    };

    useEffect(() => {
        if (state.exitingCard) {
            dispatch({ type: 'RESET' });
        }
    }, [state.exitingCard]);

    useEffect(() => {
        if (state.swipeDirection) {
            console.log(state.swipeDirection);

            if (state.swipeDirection === 'right') {
                setQuestionIndex(prev => prev + 1);
                console.log('yes' + questions[questionIndex]);
                setAnswer(questions[questionIndex].answers.yes);

                clickTransaction(events[questionIndex], 'yes');
            } else {
                setQuestionIndex(prev => prev + 1);
                console.log('no' + questions[questionIndex]);
                setAnswer(questions[questionIndex].answers.no);

                clickTransaction(events[questionIndex], 'no');
            }

            setWindowOpened(true);
        }

        if (state.activeCard) {
            console.log(state.activeCard + ' left screen');
        }
    }, [state.swipeDirection, state.activeCard]);

    useEffect(() => {
        dispatch({ type: 'RESET' });
    }, [questionIndex]);

    useEffect(() => {
        const snowflake = document.createElement('img');
        snowflake.src = '/snowflake.svg';

        setImages([snowflake]);
    }, []);

    return (
        <>
            <Snowflakes
                snowflakeCount={100}
                wind={[0, 0]}
                rotationSpeed={[1, 1]}
                radius={[1, 15]}
                images={images}
            />

            <Header />

            <div className="swipe-survey-page">
                <section className="swiper-container">
                    <button
                        className="answer-button-wrapper"
                        onClick={() => handleNo(questions.length - questionIndex - 1)}
                    >
                        <img src={XMarkIcon} alt="snowflake" className="answer-button" />
                    </button>

                    <div className="tinder-cards">
                        {/* {questions.map((question, index) => ( */}
                        {Array.from({ length: questions.length - questionIndex }).map(
                            (question, index) => (
                                <TinderCard
                                    key={index}
                                    onSwipe={dir => swiped(dir, 'item')}
                                    onCardLeftScreen={() =>
                                        handleCardExit(`card${index + 1}`)
                                    }
                                    preventSwipe={['up', 'down']}
                                    swipeRequirementType={'position'}
                                    swipeThreshold={window.innerWidth > 500 ? 100 : 30}
                                    className={`tinder-card tinder-card-${index}`}
                                >
                                    <h1>
                                        {questionIndex < 3 &&
                                            questions[questionIndex].question}
                                    </h1>

                                    <div>
                                        {questions.length - (questions.length - index) ===
                                        0 ? (
                                            <p>1 question left</p>
                                        ) : (
                                            <p>
                                                {questions.length -
                                                    (questions.length - index) +
                                                    1}{' '}
                                                questions left
                                            </p>
                                        )}
                                    </div>

                                    <div className="progress-bar">
                                        <div
                                            className="active-progress"
                                            style={{
                                                width: `${
                                                    ((questions.length - index) /
                                                        questions.length) *
                                                    100
                                                }%`,
                                            }}
                                        ></div>
                                    </div>
                                </TinderCard>
                            ),
                        )}
                    </div>

                    <button
                        className="answer-button-wrapper"
                        onClick={() => handleYes(questions.length - questionIndex - 1)}
                    >
                        <img
                            src={CheckMarkIcon}
                            alt="check mark icon"
                            className="answer-button"
                        />
                    </button>

                    <div className="swiper-buttons">
                        <button
                            onClick={() => handleNo(questions.length - questionIndex - 1)}
                        >
                            <img
                                src={XMarkIcon}
                                alt="snowflake"
                                className="answer-button"
                            />
                        </button>

                        <button
                            onClick={() =>
                                handleYes(questions.length - questionIndex - 1)
                            }
                        >
                            <img
                                src={CheckMarkIcon}
                                alt="check mark icon"
                                className="answer-button"
                            />
                        </button>
                    </div>
                </section>

                <AnswerModal
                    isWindowOpened={isWindowOpened}
                    setWindowOpened={setWindowOpened}
                    answer={answer}
                    setPage={setPage}
                    questionIndex={questionIndex}
                />
            </div>

            <Footer />
        </>
    );
}

export default SwipeSurvey;
