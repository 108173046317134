export const surveyQuestions = setQuestionIndex => {
    return [
        {
            question: {
                text: 'Where did you learn',
                span: 'about Icelandair Saga Club?',
            },
            answers: [
                {
                    answer: 'Onboard an Icelandair flight',
                },
                {
                    answer: 'Online',
                },
                {
                    answer: 'At the airport',
                },
                {
                    answer: 'In the Icelandair app',
                },
                {
                    answer: 'Other',
                },
            ],
        },
        {
            question: {
                text: 'Have you flown',
                span: 'Icelandair in the past?',
            },
            answers: [
                {
                    answer: 'Yes once',
                    redirect: true,
                    redirectTo: () => setQuestionIndex(2),
                },
                {
                    answer: 'Yes several times',
                    redirect: true,
                    redirectTo: () => setQuestionIndex(2),
                },
                {
                    answer: 'No not yet but have a booking',
                    redirect: true,
                    redirectTo: () => setQuestionIndex(3),
                },
                {
                    answer: 'No',
                    redirect: true,
                    redirectTo: () => setQuestionIndex(3),
                },
            ],
        },
        {
            question: {
                text: 'If yes, have you entered your',
                span: 'Saga Club number prior your flight?',
            },
            answers: [
                {
                    answer: 'Yes',
                },
                {
                    answer: 'No',
                },
                {
                    answer: 'No, but I retroclaimed my points',
                },
                {
                    answer: 'Not applicable',
                },
            ],
        },
        {
            question: {
                text: 'Have you used your Saga Points',
                span: 'that you earned with us?',
            },
            answers: [
                {
                    answer: 'Yes',
                },
                {
                    answer: 'No',
                },
                {
                    answer: 'Not sure',
                },
            ],
        },
        {
            question: {
                text: 'How often do you fly internationally?',
                span: '',
            },
            answers: [
                {
                    answer: '1 x per year',
                },
                {
                    answer: '2 - 4 x per year',
                },
                {
                    answer: 'Less than 1 x per year',
                },
                {
                    answer: '5 or more times a year',
                },
            ],
        },
    ];
};
