import React, { useContext } from 'react';
// import "rc-collapse/assets/index.css";
import Collapse from 'rc-collapse';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CustomButton from './ui/Button/Button';
import { AppContext } from '../contexts/AppContext';

function Faq(props) {
    const { setFaq, setTerms } = useContext(AppContext);
    const [expanded, setExpanded] = React.useState(false);
    const handleChange = panel => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : -1);
    };
    const ColAcc = ({ header, content, index }) => (
        <Accordion
        // expanded={expanded===index}
        // onChange={handleChange(index)}
        // style={{
        //     backgroundColor: "#f2f7ff"
        // }}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography sx={{ fontWeight: 'bold' }}>{header}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography>{content}</Typography>
            </AccordionDetails>
        </Accordion>
    );

    const faqTexts = [
        {
            header: 'Who can participate in the Icelandair Sweepstake game?',
            content: (
                <>
                    Any Saga Club member who is eligible to participate in the Sweepstake
                    game will have received an email from Icelandair on December 15
                    inviting them to play the game.
                    <br />
                    <br />
                    Please{' '}
                    <a
                        href={'/tnc'}
                        target={'_blank'}
                        //                                     onClick={(e) => {
                        //     e.preventDefault()
                        //     setTerms(true)
                        // }}
                    >
                        {'click\u00A0here'}
                    </a>{' '}
                    to view full game terms and conditions.
                </>
            ),
        },
        {
            header: 'How can I participate in the Icelandair Sweepstake game?',
            content:
                'You will have received an email from Icelandair with a unique link to participate in the Sweepstake game. After clicking the link, follow the instructions to play the game. On successful completion of the game, eligible participants will have an opportunity to win a prize. If you win a prize, you will receive a prize confirmation email to the email address registered to your Saga Club account or points will be added to your Saga Club Account within max 5 working days.',
        },
        {
            header: 'Do I have to complete the game to win a prize?',
            content:
                'Yes. You must complete the game to be in with a chance to win a prize.',
        },
        {
            header: 'How long do I have to complete the game?',
            content: 'The Game will end on at 11:59pm GMT on December 20.',
        },
        {
            header: 'How many times can I complete the game?',
            content: 'Participants will have the opportunity to play once.',
        },
        {
            header: 'Do I need to be a Saga Club member to participate in game?',
            content: 'Yes. See question 1',
        },
        {
            header: 'What are the prizes for the Icelandair Sweepstake Game?',
            content: (
                <>
                    {'\u00A0\u00A0\u00A0\u00A0'}First prize 50.000 Saga Points
                    <br />
                    {'\u00A0\u00A0\u00A0\u00A0'}Second prize 20.000 Saga Points
                    <br />
                    {'\u00A0\u00A0\u00A0\u00A0'}Third prize 10.000 Saga Points
                    <br />
                    {'\u00A0\u00A0\u00A0\u00A0'}Prize 4-10 - 5.000 Saga Points
                    <br />
                    <br />
                    Some other prizes of 1000 points or less
                    <br />
                    <br />
                    The prizes will be allocated randomly between December 15th and
                    December 20th 2023, and each prize is subject to its own terms and
                    conditions.
                </>
            ),
        },
        {
            header: 'When will I receive the prize I have won?',

            content:
                'If you have won 5000 Saga Points or more, you will receive a confirmation email from Icelandair once you have played the game which will confirm your prize. You will receive details on how to redeem it and any terms and conditions associated with the prize. Please allow up to 5 working days after playing the Icelandair Sweepstake game to receive the prize confirmation email. If you have won less than 5000 Saga Points, you will receive no email, the points will be submitted to your account within max.5 working days after you have completed the game.',
        },
        {
            header: 'Can I invite my friends and family to participate in the Icelandair Sweepstake?',

            content:
                'Unfortunately, only Saga Club members who received an email invitation will be eligible to play the game, and the game link cannot be shared.',
        },
        {
            header: 'What do I do if I did not receive a confirmation email from Icelandair with my prize details?',
            content: (
                <>
                    Please allow up to 5 working days to receive your confirmation email,
                    and make sure you check your Junk folder. If you still haven’t
                    received your confirmation email, please click{' '}
                    <a href={'mailto:events@icelandair.is'}>here</a>. to contact us
                    directly. Please include a copy of the Icelandair Sweepstake game
                    invitation email in your query.
                </>
            ),
        },
        {
            header: 'I was notified that I won a prize in the Icelandair Sweepstake game, but I have not received my prize according to the details in my confirmation email – what should I do?',
            content: (
                <>
                    Please click <a href={'mailto:events@icelandair.is'}>here</a> to
                    contact us and include a copy of your prize confirmation email in your
                    query.
                </>
            ),
        },
        {
            header: 'I am a Saga Club member, but I never received an email from Icelandair to participate in the Sweepstake game. What should I do? ',
            content:
                'If you did not receive an email invitation from Icelandair to participate in the Icelandair Sweepstake game, you are not eligible to participate.',
        },
        {
            header: 'I am having technical issues completing the game – what should I do?',
            content: (
                <>
                    If you’re experiencing issues playing the game, try again later, or on
                    a different device. We accept no responsibility for any difficulties
                    experienced during participation as per the{' '}
                    <a href="/tnc" target="_blank">
                        terms and conditions
                    </a>{' '}
                    of the game{' '}
                    {/* <a href={'/tnc'} target={'_blank'}>
                        {'smelltu\u00A0hér'}
                    </a> */}
                    .
                </>
            ),
        },
    ];
    return (
        <div {...props}>
            {faqTexts.map((e, i) => (
                <ColAcc {...e} index={i} key={i} />
            ))}
            {props.close && (
                <CustomButton
                    text={'BACK'}
                    onClick={() => setFaq(false)}
                    style={{ margin: '40px auto', marginBottom: '100px' }}
                />
            )}
        </div>
    );
}

export default Faq;
