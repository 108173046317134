import Header from '../components/Header/index';
import Footer from '../components/Footer';
import './campaignOver.css';

export default function CampaignOverPage() {
    return (
        <>
            <Header />

            <div className="campaign-over-page">
                <h1>Please note that the giveaway has ended</h1>
            </div>

            <Footer />
        </>
    );
}
