import React, { useRef, useEffect, useState, useContext } from 'react';
import anime from 'animejs';

import './giftPage.css';
import '../animationParts.css';

import Snowflakes from 'react-snowfall';
import SantaHead from '../assets/santaHead.svg';
import SantaHands from '../assets/santaHands.svg';
import SecretGift from '../assets/secretGift.svg';
import ShoeSvg from '../assets/shoe.svg';
import RightArrrow from '../assets/arrowRight.svg';
import Reward from '../assets/reward.svg';
import FamousPlaces from '../assets/famousPlacesBackground.svg';
import RewardPage from './reward/page';

import animationTimelineHandler from './timelines/timelineHandler';
import {
    buttonAnimationTimeline,
    buttonDisappearAnimationTimeline,
    nextButtonAnimationTimeline,
    reversedButtonAnimationTimeline,
} from './timelines/buttonTimeline';
import revealReward from './timelines/revealReward';
import Footer from '../components/Footer';
import Header from '../components/Header';
import { AppContext } from '../../../../contexts/AppContext';

const GiftPage = ({ reward }) => {
    const [images, setImages] = useState([]);

    const { clickTransaction, setPage } = useContext(AppContext);

    const arrowRightRef = useRef(null);

    const layer1Ref = useRef(null);
    const layer2Ref = useRef(null);
    const layer3Ref = useRef(null);
    const layer4Ref = useRef(null);
    const buttonRef = useRef(null);

    const nextButtonRef = useRef(null);
    const nextButtonLayer1Ref = useRef(null);
    const nextButtonLayer2Ref = useRef(null);
    const nextButtonLayer3Ref = useRef(null);
    const nextButtonLayer4Ref = useRef(null);
    const nextButtonTextRef = useRef(null);

    const thanksButtonRef = useRef(null);
    const thanksButtonLayer1Ref = useRef(null);
    const thanksButtonLayer2Ref = useRef(null);
    const thanksButtonLayer3Ref = useRef(null);
    const thanksButtonLayer4Ref = useRef(null);
    const thanksButtonTextRef = useRef(null);

    const textRef = useRef(null);

    const santasHeadRef = useRef(null);
    const santasHandsRef = useRef(null);

    const secretGiftRef = useRef(null);
    const shoeRef = useRef(null);
    const rewardRef = useRef(null);
    const rewardTextRef = useRef(null);

    const handleRevealReward = () => {
        buttonDisappearAnimationTimeline({
            layer1Ref,
            layer2Ref,
            layer3Ref,
            layer4Ref,
            textRef,
        });

        const timeline = anime.timeline();

        timeline.add({
            targets: shoeRef.current,
            opacity: 0,
            duration: 400,
            easing: 'easeInOutSine',
        });

        timeline.add(
            {
                targets: secretGiftRef.current,
                opacity: 0,
                duration: 400,
                easing: 'easeInOutSine',
            },
            '-=100',
        );

        timeline.play();

        clickTransaction('reward');

        setPage(
            <RewardPage
                reward={reward}
                refs={{
                    thanksButtonLayer1Ref,
                    thanksButtonLayer2Ref,
                    thanksButtonLayer3Ref,
                    thanksButtonLayer4Ref,
                    thanksButtonTextRef,
                }}
            />,
        );

        // revealReward({ shoeRef, rewardRef, rewardTextRef, secretGiftRef });

        // const thanksButtonTimeline = anime.timeline();

        // reversedButtonAnimationTimeline({
        //     layer1Ref: thanksButtonLayer1Ref,
        //     layer2Ref: thanksButtonLayer2Ref,
        //     layer3Ref: thanksButtonLayer3Ref,
        //     layer4Ref: thanksButtonLayer4Ref,
        //     textRef: thanksButtonTextRef,
        // });

        // thanksButtonTimeline.play();
    };

    const handleNext = () => {
        buttonDisappearAnimationTimeline({
            layer1Ref: nextButtonLayer1Ref,
            layer2Ref: nextButtonLayer2Ref,
            layer3Ref: nextButtonLayer3Ref,
            layer4Ref: nextButtonLayer4Ref,
            textRef: nextButtonTextRef,
        });

        animationTimelineHandler({
            santasHeadRef,
            santasHandsRef,
            secretGiftRef,
            layer1Ref,
            layer2Ref,
            layer3Ref,
            layer4Ref,
            textRef,
            shoeRef,
        });

        secretGiftRef.current.style.display = 'block';
        shoeRef.current.style.display = 'block';
    };

    useEffect(() => {
        // animationTimelineHandler({
        //     santasHeadRef,
        //     santasHandsRef,
        //     secretGiftRef,
        //     layer1Ref,
        //     layer2Ref,
        //     layer3Ref,
        //     layer4Ref,
        //     textRef,
        //     shoeRef,
        // });

        nextButtonAnimationTimeline({
            layer1Ref: nextButtonLayer1Ref,
            layer2Ref: nextButtonLayer2Ref,
            layer3Ref: nextButtonLayer3Ref,
            layer4Ref: nextButtonLayer4Ref,
            textRef: nextButtonTextRef,
        });

        anime({
            targets: arrowRightRef.current,
            direction: 'alternate',
            easing: 'easeInOutSine',
            position: 'absolute',
            left: ['65%', '60%'],
            transform: 'translate(-50%, -50%)',
            duration: 800,
            loop: true,
        });
    }, []);

    useEffect(() => {
        const snowflake = document.createElement('img');
        snowflake.src = '/snowflake.svg';

        setImages([snowflake]);
    }, []);

    return (
        <>
            <Header />
            <div className="gift-page">
                <Snowflakes
                    snowflakeCount={100}
                    wind={[0, 0]}
                    rotationSpeed={[1, 1]}
                    radius={[1, 15]}
                    images={images}
                />

                <button
                    ref={nextButtonRef}
                    className="button-container next-btn"
                    onClick={() => handleNext()}
                >
                    <div ref={nextButtonLayer1Ref} className="layer"></div>
                    <div ref={nextButtonLayer2Ref} className="layer"></div>
                    <div ref={nextButtonLayer3Ref} className="layer"></div>
                    <div ref={nextButtonLayer4Ref} className="layer"></div>
                    <span ref={nextButtonTextRef} className="next-button-text">
                        Next
                        <span className="arrow-right">
                            <img
                                ref={arrowRightRef}
                                src={RightArrrow}
                                alt="Arrow right"
                                className="arrow-right-img"
                            />
                        </span>
                    </span>
                </button>

                <button
                    ref={buttonRef}
                    className="button-container"
                    onClick={() => handleRevealReward()}
                >
                    <div ref={layer1Ref} className="layer"></div>
                    <div ref={layer2Ref} className="layer"></div>
                    <div ref={layer3Ref} className="layer"></div>
                    <div ref={layer4Ref} className="layer"></div>
                    <span ref={textRef} className="button-text">
                        Tap To Reveal!
                    </span>
                </button>

                <div
                    ref={thanksButtonRef}
                    className="button-container thanks-btn"
                    // onClick={() => handleNext()}
                >
                    <div ref={thanksButtonLayer1Ref} className="layer"></div>
                    <div ref={thanksButtonLayer2Ref} className="layer"></div>
                    <div ref={thanksButtonLayer3Ref} className="layer"></div>
                    <div ref={thanksButtonLayer4Ref} className="layer"></div>
                    <span ref={thanksButtonTextRef} className="button-text">
                        Thank You!
                    </span>
                </div>

                <img
                    src={SantaHead}
                    className="santa-head"
                    alt="Santa head"
                    ref={santasHeadRef}
                    style={{
                        bottom: '-20%',
                        transform: 'translateX(-50%) rotate(0deg)',
                    }}
                />

                <img
                    src={SantaHands}
                    className="santa-hands"
                    alt="Santa hands"
                    ref={santasHandsRef}
                />

                <div>
                    <img
                        src={SecretGift}
                        className="secret-gift"
                        alt="secret-gift"
                        ref={secretGiftRef}
                        style={{
                            left: '50%',
                            top: '50%',
                            transform: 'translateX(-50%) translateY(-50%) scale(0)',
                            display: 'none',
                        }}
                    />

                    <button onClick={() => handleRevealReward()}>
                        <img
                            src={ShoeSvg}
                            alt="Shoe"
                            className="shoe"
                            ref={shoeRef}
                            style={{
                                opacity: 0,
                                display: 'none',
                            }}
                        />
                    </button>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default GiftPage;
