export const variantsSurveyQuestions = () => {
    return [
        {
            question: {
                text: 'Saga Club members can earn Saga Points when flying Icelandair,',
                span: 'but also on other airlines. Do you know which ones?',
            },
            answers: [
                'LATAM & Air Baltic',
                'Alaska Airlines & JetBlue',
                'JetBlue & Air Baltic',
                'Alaska Airlines & LATAM',
            ],
            rightAnswer: 'Alaska Airlines & JetBlue',
        },
        {
            question: {
                text: 'With Icelandair Saga Club you can retroclaim (register back in time)',
                span: 'your Saga Points from past flights, how far back?',
            },
            answers: ['3 months', '1 week', '12 months', '24 months'],
            rightAnswer: '12 months',
        },
    ];
};
