import './openQuestion.css';
import Snowflakes from 'react-snowfall';
import { useEffect, useState, useRef, useContext } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Textarea from '../components/Textarea';
import ArrowRight from '../assets/arrowRight.svg';
import anime from 'animejs';
import {
    submitButtonAnimationTimeline,
    submitButtonDisappearAnimationTimeline,
} from './timeline/button';
import { AppContext } from '../../../../contexts/AppContext';
import GiftPage from '../giftPage/giftPage';
import {
    layersAnimation,
    layersDisappearAnimationTimeline,
} from '../components/Textarea/timeline/layers';
import LittleSanta from '../assets/littleSanta.svg';
import MessageCloud from '../assets/messageCloud.svg';
import SwipeSurvey from '../swipeSurvey/page';
import { littleSantaAnimationTimeline } from './timeline/littleSantaTimeline';
import DatalistedInput from '../components/DatalistedInput';
import SubmitButton from '../components/SubmitButton';

export default function OpenQuestionPage({ load }) {
    const events = ['destination', 'airline'];

    const eventIndex = events.indexOf(load);

    const [images, setImages] = useState([]);
    const [questions] = useState([
        'Which of these Icelandair destinations do you mostly fly to?',
        'Which is your favorite airline?',
    ]);
    const [questionIndex, setQuestionIndex] = useState(
        eventIndex === +'-1' ? 0 : eventIndex,
    );
    const [answer, setAnswer] = useState('');
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);
    const [animationStarted, setAnimationStarted] = useState(false);
    const [city, setCity] = useState('');

    const { setPage, clickTransaction, pages } = useContext(AppContext);

    const submitButtonRef = useRef(null);
    const submitButtonLayer1Ref = useRef(null);
    const submitButtonLayer2Ref = useRef(null);
    const submitButtonLayer3Ref = useRef(null);
    const submitButtonLayer4Ref = useRef(null);
    const submitButtonTextRef = useRef(null);
    const arrowRightRef = useRef(null);
    const littleSantaRef = useRef(null);
    const cloudMessageRef = useRef(null);

    const layersRefs = {
        layer1: useRef(null),
        layer2: useRef(null),
        layer3: useRef(null),
        layer4: useRef(null),
    };

    useEffect(() => {
        submitButtonAnimationTimeline({
            layer1Ref: submitButtonLayer1Ref,
            layer2Ref: submitButtonLayer2Ref,
            layer3Ref: submitButtonLayer3Ref,
            layer4Ref: submitButtonLayer4Ref,
            textRef: submitButtonTextRef,
            submitButtonRef,
        });

        layersAnimation(layersRefs);
    }, [questionIndex]);

    useEffect(() => {
        if (answer && answer.length > 0) setSubmitButtonDisabled(false);
        if (answer && !animationStarted) setAnimationStarted(true);

        if (!answer) {
            anime.remove(arrowRightRef.current);
            arrowRightRef.current.style.left = '75%';
            setSubmitButtonDisabled(false);
            setAnimationStarted(false);
        } else {
            if (!animationStarted) setAnimationStarted(true);
        }
    }, [answer]);

    useEffect(() => {
        if (answer && animationStarted) {
            console.log('animation started');

            anime({
                targets: arrowRightRef.current,
                direction: 'alternate',
                easing: 'easeInOutSine',
                position: 'absolute',
                left: ['75%', '85%'],
                transform: 'translate(-50%, -50%)',
                duration: 600,
                loop: true,
            });
        }
    }, [animationStarted]);

    useEffect(() => {
        const snowflake = document.createElement('img');
        snowflake.src = '/snowflake.svg';

        setImages([snowflake]);
    }, []);

    useEffect(() => {
        setSubmitButtonDisabled(true);
    }, []);

    useEffect(() => {
        littleSantaAnimationTimeline(littleSantaRef, cloudMessageRef);
    }, []);

    const handleAnswerSubmit = () => {
        clickTransaction(events[questionIndex], answer);

        if (questionIndex < questions.length - 1) {
            setSubmitButtonDisabled(true);

            layersDisappearAnimationTimeline(layersRefs);

            submitButtonDisappearAnimationTimeline({
                layer1Ref: submitButtonLayer1Ref,
                layer2Ref: submitButtonLayer2Ref,
                layer3Ref: submitButtonLayer3Ref,
                layer4Ref: submitButtonLayer4Ref,
                textRef: submitButtonTextRef,
                submitButtonRef,
            });

            setTimeout(() => {
                setQuestionIndex(questionIndex + 1);
                setAnswer('');
            }, 2000);
        } else {
            setSubmitButtonDisabled(true);

            layersDisappearAnimationTimeline(layersRefs);

            submitButtonDisappearAnimationTimeline({
                layer1Ref: submitButtonLayer1Ref,
                layer2Ref: submitButtonLayer2Ref,
                layer3Ref: submitButtonLayer3Ref,
                layer4Ref: submitButtonLayer4Ref,
                textRef: submitButtonTextRef,
                submitButtonRef,
            });

            setTimeout(() => {
                // setPage(<GiftPage />);
                // setPage(pages.SwipeSurveyPage);
                setPage(<SwipeSurvey />);
            }, 2000);
        }
    };

    return (
        <>
            <Snowflakes
                snowflakeCount={100}
                wind={[0, 0]}
                rotationSpeed={[1, 1]}
                radius={[1, 15]}
                images={images}
            />

            <Header />

            <section className="open-question-page">
                <h1>{questions[questionIndex]}</h1>

                {questionIndex === 0 ? (
                    <DatalistedInput
                        value={answer}
                        onChange={e => setAnswer(e.target.value)}
                        refs={layersRefs}
                    />
                ) : (
                    <Textarea
                        value={answer}
                        onChange={e => setAnswer(e.target.value)}
                        placholder={'Enter your answer here'}
                        refs={layersRefs}
                    />
                )}

                {/* <button
                    onClick={handleAnswerSubmit}
                    ref={submitButtonRef}
                    className={`button-container submit-button ${
                        answer && answer.length > 0 ? 'selected-option' : ''
                    }`}
                    disabled={submitButtonDisabled}
                >
                    <div ref={submitButtonLayer1Ref} className="layer"></div>
                    <div ref={submitButtonLayer2Ref} className="layer"></div>
                    <div ref={submitButtonLayer3Ref} className="layer"></div>
                    <div ref={submitButtonLayer4Ref} className="layer"></div>
                    <div
                        ref={submitButtonTextRef}
                        className="button-text"
                        style={{ opacity: 0 }}
                    >
                        <div className="button-text-content">
                            Submit
                            <span
                                className="arrow-right"
                                ref={arrowRightRef}
                                style={{
                                    left: '75%',
                                }}
                            >
                                <img
                                    ref={arrowRightRef}
                                    src={ArrowRight}
                                    alt="Arrow right"
                                    className="arrow-right-img"
                                />
                            </span>
                        </div>
                    </div>
                </button> */}

                <SubmitButton
                    label={'Submit'}
                    refs={{
                        submitButtonRef,
                        submitButtonLayer1Ref,
                        submitButtonLayer2Ref,
                        submitButtonLayer3Ref,
                        submitButtonLayer4Ref,
                        submitButtonTextRef,
                        arrowRightRef,
                    }}
                    onClick={handleAnswerSubmit}
                    selectedAnswer={answer}
                    submitButtonDisabled={submitButtonDisabled}
                />
            </section>

            <Footer withSanta questionIndex={3} />
        </>
    );
}
