import { useState, useContext } from "react";
import LargeButton from "../../ui/Button/LargeButton";
import "./EarnPage.css";
import { AppContext } from "../../../contexts/AppContext";
import Ch1 from "../Ch1";
import CustomButton from "../../ui/Button/Button";
import InteractiveChoicePage from "../interactiveChoicePage/page";
import PageScheme from "../../ui/pageScheme/pageScheme";
import ReactGA4 from "react-ga4";
import { useEffect } from "react";

export default function EarnPage() {
    const { setPage, clickTransaction } = useContext(AppContext);
    const [questionIndex, setQuestionIndex] = useState(0);
    const [selectedOption, setSelectedOption] = useState(-1);

    const [questions] = useState([
        {
            title: "With Icelandair Saga Club you can earn points by flying Icelandair, but also on other airlines. Do you know which ones?",
            options: {
                a: "LATAM & Air Baltic",
                b: "Alaska Airlines & JetBlue",
                c: "JetBlue & Air Baltic",
                d: "Alaska Airlines & LATAM",
            },
        },
        {
            title: "With Icelandair Saga Club you can retroclaim your points from past flights for how long?",
            options: {
                a: "3 months",
                b: "1 week",
                c: "12 months",
                d: "24 months",
            },
        },
    ]);

    useEffect(() => {
        ReactGA4.event({
            category: "page",
            action: `CH2Q${questionIndex}`,
        });
    }, [questionIndex]);

    const handleSubmit = (answer) => {
        if (questionIndex === questions.length - 1) {
            setPage(<InteractiveChoicePage />);
        } else {
            if (selectedOption !== -1) {
                setQuestionIndex(questionIndex + 1);
                setSelectedOption(-1);
            }
        }
    };

    return (
        <PageScheme>
            <div>
                <h1>{questions[questionIndex].title}</h1>

                <div className="options">
                    <LargeButton
                        text={questions[questionIndex].options.a}
                        onClick={() => setSelectedOption(1)}
                        style={
                            selectedOption === 1
                                ? {
                                      backgroundColor: "var(--color-1)",
                                      color: "white",
                                  }
                                : {}
                        }
                    />

                    <LargeButton
                        text={questions[questionIndex].options.b}
                        onClick={() => setSelectedOption(2)}
                        style={
                            selectedOption === 2
                                ? {
                                      backgroundColor: "var(--color-1)",
                                      color: "white",
                                  }
                                : {}
                        }
                    />

                    <LargeButton
                        text={questions[questionIndex].options.c}
                        onClick={() => setSelectedOption(3)}
                        style={
                            selectedOption === 3
                                ? {
                                      backgroundColor: "var(--color-1)",
                                      color: "white",
                                  }
                                : {}
                        }
                    />

                    <LargeButton
                        text={questions[questionIndex].options.d}
                        onClick={() => setSelectedOption(4)}
                        style={
                            selectedOption === 4
                                ? {
                                      backgroundColor: "var(--color-1)",
                                      color: "white",
                                  }
                                : {}
                        }
                    />
                </div>

                <CustomButton text="Submit" onClick={() => handleSubmit()} />
            </div>
        </PageScheme>
    );
}
