import anime from "animejs";
import { buttonAnimationTimeline } from "./buttonTimeline";
import { estimatedTimeAnimationTimeline } from "./estimatedTimeTimelines";
import { arrowLoopAnimationTimeline } from "./arrowLoop";

export default function animationTimelineHandler(refs) {
    const timeline = anime.timeline();

    estimatedTimeAnimationTimeline({
        timeline: timeline,
        refs: {
            layer1Ref: refs.estimatedTimeLayer1Ref,
            layer2Ref: refs.estimatedTimeLayer2Ref,
            layer3Ref: refs.estimatedTimeLayer3Ref,
            layer4Ref: refs.estimatedTimeLayer4Ref,
            textRef: refs.estimatedTimeTextRef,
        },
    });

    buttonAnimationTimeline({
        timeline: timeline,
        refs: {
            layer1Ref: refs.buttonLayer1Ref,
            layer2Ref: refs.buttonLayer2Ref,
            layer3Ref: refs.buttonLayer3Ref,
            layer4Ref: refs.buttonLayer4Ref,
            textRef: refs.buttonTextRef,
        },
    });

    // arrowLoopAnimationTimeline({
    //     timeline: timeline,
    //     refs: {
    //         arrowRightRef: refs.arrowRightRef,
    //     },
    // });

    timeline.play();
}
