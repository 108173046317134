import { useState, useRef, useEffect } from 'react';
import './DatalistedInput.css';
import citiesJSON from './cities.json';
import { layersAnimation } from '../Textarea/timeline/layers';

export default function DatalistedInput({ value, onChange, refs }) {
    // const [cities] = useState(JSON.parse(citiesJSON));

    const layersRefs = {
        layer1: useRef(null),
        layer2: useRef(null),
        layer3: useRef(null),
        layer4: useRef(null),
    };

    useEffect(() => {
        layersAnimation(layersRefs);
    }, []);

    return (
        <>
            <div className="datalisted-input-wrapper">
                <div className="layer" ref={refs.layer1}></div>
                <div className="layer" ref={refs.layer2}></div>
                <div className="layer" ref={refs.layer3}></div>
                <input
                    type="text"
                    value={value}
                    onChange={onChange}
                    className="datalisted-input layer"
                    list="cities-datalist"
                    ref={refs.layer4}
                />
            </div>
            <datalist id="cities-datalist">
                {citiesJSON.cities.map(city => (
                    <option key={city} value={city}>
                        {city}
                    </option>
                ))}
            </datalist>
        </>
    );
}
