export const secretGiftAnimationTimeline = ({ timeline, secretGiftRef }) => {
    timeline.add({
        targets: secretGiftRef.current,
        scale: "1.2",
        top: "50%",
        left: "50%",
        translateX: "-50%",
        translateY: "-50%",
        duration: 500,
        easing: "easeInOutSine",
    });

    timeline.add({
        targets: secretGiftRef.current,
        scale: "1",
        translateX: "-50%",
        translateY: "-50%",
        duration: 500,
        easing: "easeInOutSine",
    });
};
