import ArrowRight from '../../assets/arrowRight.svg';
import './SubmitButton.css';

export default function SubmitButton({
    label,
    refs,
    onClick,
    selectedAnswer,
    submitButtonDisabled,
}) {
    return (
        <button
            onClick={onClick}
            ref={refs.submitButtonRef}
            className={`submit-button ${selectedAnswer !== -1 ? 'selected-option' : ''}`}
            disabled={submitButtonDisabled}
        >
            <div ref={refs.submitButtonLayer1Ref} className="layer"></div>
            <div ref={refs.submitButtonLayer2Ref} className="layer"></div>
            <div ref={refs.submitButtonLayer3Ref} className="layer"></div>
            <div ref={refs.submitButtonLayer4Ref} className="layer"></div>
            <div
                ref={refs.submitButtonTextRef}
                className="button-text"
                style={{ opacity: 0 }}
            >
                <div className="button-text-content">
                    Submit{' '}
                    <span
                        className="arrow-right"
                        ref={refs.arrowRightRef}
                        style={
                            {
                                // top: '50%',
                                // left: '70%',
                                // transform: 'translate(-50%, -50%)',
                            }
                        }
                    >
                        <img
                            ref={refs.arrowRightRef}
                            src={ArrowRight}
                            alt="Arrow right"
                            className="arrow-right-img"
                        />
                    </span>
                </div>
            </div>
        </button>
    );
}
