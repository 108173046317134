import Header from '../components/Header';
import Footer from '../components/Footer';
import './userUnsigned.css';

export default function UserUnsignedPage() {
    return (
        <>
            <Header />

            <div className="user-unsigned-page">
                <h1>
                    Unfortunately, only Saga Club members who received the game invite
                    sent by email from Icelandair can participate in the giveaway.
                </h1>
            </div>

            <Footer />
        </>
    );
}
