import anime from 'animejs';

export const littleSantaAnimationTimeline = (
    timeline,
    santaRef,
    cloudRef,
    // cloudTextRef,
    santaDisappear,
) => {
    cloudRef.current.style.display = 'block';
    // cloudTextRef.current.style.display = 'block';

    timeline.add(
        {
            targets: santaRef.current,
            bottom: '-19%',
            duration: 400,
            easing: 'easeInOutSine',
        },
        '+=500',
    );

    timeline.add({
        targets: santaRef.current,
        bottom: '-20%',
        duration: 300,
        easing: 'easeInOutSine',
    });

    timeline.add({
        targets: cloudRef.current,
        opacity: 1,
        duration: 200,
        easing: 'easeInOutSine',
    });

    // timeline.add({
    //     targets: cloudTextRef.current,
    //     opacity: 1,
    //     duration: 200,
    //     easing: 'easeInOutSine',
    // });

    if (santaDisappear) {
        // timeline.add({
        //     targets: cloudTextRef.current,
        //     opacity: 0,
        //     duration: 200,
        //     easing: 'easeInOutSine',
        //     delay: 5000,
        //     complete: () => {
        //         cloudTextRef.current.style.display = 'none';
        //     },
        // });

        timeline.add({
            targets: cloudRef.current,
            opacity: 0,
            duration: 300,
            easing: 'easeInOutSine',
            delay: 5000,
            complete: () => {
                console.log(cloudRef.current);
                cloudRef.current.style.display = 'none';
            },
        });

        // timeline.add({
        //     targets: cloudTextRef.current,
        //     bottom: '-1000%',
        //     duration: 100,
        //     easing: 'easeInOutSine',
        // });

        // timeline.add({
        //     targets: cloudRef.current,
        //     position: 'absolute',
        //     bottom: '-1000%',
        //     duration: 100,
        //     easing: 'easeInOutSine',
        // });

        timeline.add(
            {
                targets: santaRef.current,
                bottom: '-20%',
                duration: 300,
                easing: 'easeInOutSine',
            },
            '-=200',
        );

        timeline.add({
            targets: santaRef.current,
            bottom: '-1000%',
            duration: 300,
            easing: 'easeInOutSine',
        });
    }
};
