import anime from 'animejs';

export function variantColoring(rigthAnswerIndex, buttonsRefs) {
    const wrongAnswers = buttonsRefs.filter((_, index) => index !== rigthAnswerIndex);

    console.log(buttonsRefs);
    console.log(wrongAnswers);
    console.log(buttonsRefs[rigthAnswerIndex]);

    const timeline = anime.timeline();

    wrongAnswers.forEach(button => {
        console.log(button);
        console.log(button.layer4Ref);

        timeline.add({
            targets: [button.layer4Ref.current, button.layer1Ref.current],
            backgroundColor: '#B21E1E',
            duration: 500,
            easing: 'easeInOutSine',
        });
    });

    timeline.add({
        targets: [
            buttonsRefs[rigthAnswerIndex].layer4Ref.current,
            buttonsRefs[rigthAnswerIndex].layer1Ref.current,
        ],
        backgroundColor: '#1EB220',
        duration: 500,
        easing: 'easeInOutSine',
    });

    timeline.play();
}
