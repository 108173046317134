import { Dialog } from '@headlessui/react';
import XMarkSmall from '../../assets/xmarkSmall.svg';
import { useContext, useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import anime from 'animejs';
import './AnswerModal.css';
import GiftPage from '../../giftPage/giftPage';
import { AppContext } from '../../../../../contexts/AppContext';
import { pages } from '../../../../../App';

export default function AnswerModal({
    isWindowOpened,
    setWindowOpened,
    answer,
    setPage,
    questionIndex,
}) {
    const modalWindowRef = useRef(null);

    const { getMember } = useContext(AppContext);

    const onClose = () => {
        console.log(questionIndex);

        if (questionIndex === 3) {
            getMember().then(json => {
                if (
                    !(
                        json &&
                        json.data &&
                        json.data.engage2reward &&
                        typeof json.data.engage2reward == 'string'
                    )
                )
                    return setPage(pages.Error);

                setPage(<GiftPage reward={json.data.engage2reward} />);
            });
        } else {
            setWindowOpened(false);
        }
    };

    useEffect(() => {
        anime({
            targets: modalWindowRef.current,
            opacity: 1,
            duration: 300,
            easing: 'easeInOutQuad',
        });
    }, [isWindowOpened]);

    return (
        <Dialog
            open={isWindowOpened}
            onClose={onClose}
            className="modal-window"
            style={{ opacity: 0 }}
            ref={modalWindowRef}
        >
            <Dialog.Panel className={'modal-panel'}>
                <button onClick={() => onClose()}>
                    <img src={XMarkSmall} alt="X mark small" />
                </button>

                <p>{answer}</p>
            </Dialog.Panel>
        </Dialog>
    );
}
