import anime from "animejs";

export const buttonAnimationTimeline = ({ timeline, refs }) => {
    timeline.add(
        {
            targets: refs.layer1Ref.current,
            maxWidth: "100%",
            duration: 500,
            easing: "easeInOutSine",
        },
        "-=400"
    );

    timeline.add(
        {
            targets: refs.layer2Ref.current,
            maxWidth: "calc(100% - 20px)",
            duration: 400,
            easing: "easeInOutSine",
        },
        "-=200"
    );

    timeline.add(
        {
            targets: refs.layer3Ref.current,
            maxWidth: "calc(100% - 20px)",
            duration: 500,
            easing: "easeInOutSine",
        },
        "-=300"
    );

    timeline.add(
        {
            targets: refs.layer4Ref.current,
            maxWidth: "calc(100% - 20px)",
            duration: 700,
            easing: "easeInOutSine",
            complete: () => {
                anime({
                    targets: refs.textRef.current,
                    opacity: 1,
                    duration: 300,
                    easing: "easeInOutSine",
                });
            },
        },
        "-=500"
    );
};

export const buttonDisappearAnimationTimeline = (refs, delay) => {
    const timeline = anime.timeline();

    timeline.add(
        {
            targets: refs.textRef.current,
            opacity: 0,
            duration: 200,
            easing: "easeInOutSine",
        },
        `+=${delay}`
    );

    timeline.add(
        {
            targets: refs.layer4Ref.current,
            width: "0px",
            duration: 700,
            easing: "easeInOutSine",
        }
        // "-=600"
    );

    timeline.add(
        {
            targets: refs.layer3Ref.current,
            width: "0px",
            duration: 600,
            easing: "easeInOutSine",
        },
        "-=400"
    );

    timeline.add(
        {
            targets: refs.layer2Ref.current,
            width: "0px",
            duration: 500,
            easing: "easeInOutSine",
        },
        "-=300"
    );

    timeline.add(
        {
            targets: refs.layer1Ref.current,
            width: "0px",
            duration: 400,
            easing: "easeInOutSine",
        },
        "-=300"
    );

    timeline.play();
};
