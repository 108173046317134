import anime from 'animejs';

export default function revealReward(refs, revealButton, thanksButton) {
    console.log(refs.rewardTextRef);

    const timeline = anime.timeline();

    // timeline.add({
    //     targets: refs.shoeRef.current,
    //     opacity: 0,
    //     duration: 400,
    //     easing: 'easeInOutSine',
    // });

    // timeline.add(
    //     {
    //         targets: refs.secretGiftRef.current,
    //         opacity: 0,
    //         duration: 400,
    //         easing: 'easeInOutSine',
    //     },
    //     '-=100',
    // );

    // timeline.add({
    //     begin: () => {
    //         refs.rewardRef.current.style.display = "block";
    //     },
    // });

    timeline.add(
        {
            targets: refs.rewardRef.current,
            opacity: 0.6,
            duration: 400,
            easing: 'easeInOutSine',
        },
        '+=150',
    );

    timeline.add(
        {
            targets: refs.rewardTextRef.current,
            opacity: 1,
            duration: 500,
            easing: 'easeInOutSine',
        },
        '+=100',
    );

    timeline.play();
}
