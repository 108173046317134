import anime from "animejs";

export const submitButtonAnimationTimeline = (refs) => {
    const timeline = anime.timeline();

    timeline.add(
        {
            targets: refs.layer1Ref.current,
            maxWidth: "100%",
            duration: 400,
            easing: "easeInOutSine",
        },
        `+=200`
    );

    timeline.add(
        {
            targets: refs.layer2Ref.current,
            maxWidth: "97.5%",
            duration: 600,
            easing: "easeInOutSine",
        },
        "-=400"
    );

    timeline.add(
        {
            targets: refs.layer3Ref.current,
            maxWidth: "97.5%",
            duration: 700,
            easing: "easeInOutSine",
        },
        "-=500"
    );

    timeline.add(
        {
            targets: refs.layer4Ref.current,
            maxWidth: "97.5%",
            duration: 900,
            easing: "easeInOutSine",
            complete: () => {
                anime({
                    targets: refs.textRef.current,
                    opacity: 1,
                    duration: 150,
                    easing: "easeInOutSine",
                });
            },
        },
        "-=700"
    );

    timeline.play();

    setTimeout(() => {
        refs.submitButtonRef.current.disabled = false;
    }, timeline.duration);
};

export const submitButtonDisappearAnimationTimeline = (refs) => {
    const timeline = anime.timeline();

    timeline.add({
        targets: refs.textRef.current,
        opacity: 0,
        duration: 200,
        easing: "easeInOutSine",
    });

    timeline.add(
        {
            targets: refs.layer4Ref.current,
            maxWidth: "0%",
            duration: 700,
            easing: "easeInOutSine",
        }
        // "-=500"
    );

    timeline.add(
        {
            targets: refs.layer3Ref.current,
            maxWidth: "0%",
            duration: 600,
            easing: "easeInOutSine",
        },
        "-=400"
    );

    timeline.add(
        {
            targets: refs.layer2Ref.current,
            maxWidth: "0%",
            duration: 500,
            easing: "easeInOutSine",
        },
        "-=300"
    );

    timeline.add(
        {
            targets: refs.layer1Ref.current,
            maxWidth: "0%",
            duration: 400,
            easing: "easeInOutSine",
        },
        "-=200"
    );

    timeline.play();
};
