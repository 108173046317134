import { useContext } from 'react';
import { AppContext } from '../../../../../contexts/AppContext';
import IcelandAirLogo from '../../assets/icelandairHeaderLogo.svg';
import { motion } from 'framer-motion';
import closeIcon from '../../assets/closeIcon.svg';
import './Header.css';

export default function Header() {
    const { setTerms, setFaq, terms, faq, name } = useContext(AppContext);

    return (
        <header className="header">
            <h1>{name}</h1>

            <a className="logo" href="https://www.icelandair.com/" target="_blank">
                <img src={IcelandAirLogo} alt="Icelandair Header Logo" />
            </a>

            {(terms || faq) && (
                <motion.button
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    className={`close`}
                    style={{ aspectRatio: 1 }}
                    onClick={() => {
                        setFaq(false);
                        setTerms(false);
                    }}
                >
                    {closeIcon}
                </motion.button>
            )}
        </header>
    );
}
