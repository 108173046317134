import Footer from '../../components/Footer';
import Header from '../../components/Header';
import FamousPlaces from '../../assets/famousPlacesBackground.svg';
import { useEffect, useRef, useState, createRef } from 'react';
import { thanksButtonAnimationTimeline } from '../timelines/buttonTimeline';
import revealReward from '../timelines/revealReward';
import anime from 'animejs';
import Snowflakes from 'react-snowfall';

import './reward.css';
import '../../animationParts.css';
import '../giftPage.css';

export default function RewardPage({ reward }) {
    const rewardRef = useRef(null);
    const rewardTextRef = useRef(null);

    const thanksButtonRefs = {
        thanksButtonRef: createRef(null),
        thanksButtonLayer1Ref: createRef(null),
        thanksButtonLayer2Ref: createRef(null),
        thanksButtonLayer3Ref: createRef(null),
        thanksButtonLayer4Ref: createRef(null),
        thanksButtonTextRef: createRef(null),
    };

    const [images, setImages] = useState([]);

    useEffect(() => {
        const snowflake = document.createElement('img');
        snowflake.src = '/snowflake.svg';

        setImages([snowflake]);
    }, []);

    useEffect(() => {
        // buttonDisappearAnimationTimeline({
        //     layer1Ref,
        //     layer2Ref,
        //     layer3Ref,
        //     layer4Ref,
        //     textRef,
        // });

        rewardRef.current.style.display = 'block';

        revealReward({ rewardRef, rewardTextRef });

        thanksButtonAnimationTimeline({
            layer1Ref: thanksButtonRefs.thanksButtonLayer1Ref,
            layer2Ref: thanksButtonRefs.thanksButtonLayer2Ref,
            layer3Ref: thanksButtonRefs.thanksButtonLayer3Ref,
            layer4Ref: thanksButtonRefs.thanksButtonLayer4Ref,
            textRef: thanksButtonRefs.thanksButtonTextRef,
        });
    }, []);

    return (
        <>
            <Snowflakes
                snowflakeCount={100}
                wind={[0, 0]}
                rotationSpeed={[1, 1]}
                radius={[1, 15]}
                images={images}
            />

            <Header />

            <div className="reward-page">
                <div className="reward-wrapper">
                    <h1 className="title">
                        Congratulations! <span>You won!</span>
                    </h1>

                    <div className="reward-image-wrapper">
                        <img
                            src={FamousPlaces}
                            alt="Reward"
                            className="reward"
                            ref={rewardRef}
                            style={{ opacity: 0, display: 'none' }}
                        />

                        <h1 className="reward-amount" ref={rewardTextRef}>
                            {reward} <span>Saga Points</span>
                        </h1>
                    </div>
                </div>

                <p className="points-notify">
                    Your Points will be added to your Icelandair Saga Club Account within
                    the next couple of days.
                </p>

                <div
                    ref={thanksButtonRefs.thanksButtonRef}
                    className="button-container thanks-btn"
                    // onClick={() => handleNext()}
                >
                    <div
                        ref={thanksButtonRefs.thanksButtonLayer1Ref}
                        className="layer"
                    ></div>
                    <div
                        ref={thanksButtonRefs.thanksButtonLayer2Ref}
                        className="layer"
                    ></div>
                    <div
                        ref={thanksButtonRefs.thanksButtonLayer3Ref}
                        className="layer"
                    ></div>
                    <div
                        ref={thanksButtonRefs.thanksButtonLayer4Ref}
                        className="layer"
                    ></div>
                    <span
                        ref={thanksButtonRefs.thanksButtonTextRef}
                        className="button-text"
                    >
                        Thank You!
                    </span>
                </div>
            </div>

            <Footer withSanta questionIndex={4} linkedSanta santaDisappear={false} />
        </>
    );
}
